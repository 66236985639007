import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Container from '@mui/material/Container';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom'; 
import {apiLogin} from '../../services/api/accounts/Login';
import { useTranslation } from "react-i18next";

const RegistrationComponent = () => {
    const { register, handleSubmit, reset } = useForm();
    const [validationMessage, setValidationMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { t, i18n, ready } = useTranslation();

    const onSubmit = async (data) => {
        if(!isLoading)
        {
            setIsLoading(true);
            let result = await apiLogin.registration(data);
            if (result.error) {
                setIsLoading(false);
                setValidationMessage(result.error);
            }
            else {
                window.location.href = "/login?type=2";
            }
        }
    };

    let location = useLocation();

    useEffect(() => {
    }, [reset]);

    return (
        <Grid container className="center-vertical-item">
            <Container component="main" maxWidth="sm" className="login-container height100">
                <Link className="logo-small" to="/login"></Link>
                <Grid container direction="column" className="height100">
                    <Grid className="login-first-row">
                        <form onSubmit={handleSubmit(onSubmit)} className="login-form">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <h1>{t("registration_text1")}</h1>
                                    <h2>{t("registration_text2")}</h2>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="validation-error">{validationMessage}</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel>{t("registration_text3")}</FormLabel>
                                    <TextField autoComplete='off' required autoFocus {...register("email", { required: true })} /><br />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel>{t("registration_text4")}</FormLabel>
                                    <TextField autoComplete='off' required {...register("name", { required: true })} /><br />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel>{t("registration_text5")}</FormLabel>
                                    <TextField autoComplete='off' required {...register("surname", { required: true })} /><br />
                                </Grid>
                                <br/>
                                <Grid item xs={12}>
                                    <Button type="submit" className="button" fullWidth color="primary">
                                        {isLoading && 
                                            <span className="loader"></span>
                                        }
                                        {!isLoading && 
                                            <span>{t("registration_text6")}</span>
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid className="login-options">
                        <div className="space"></div>
                        <br/>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
}

export default RegistrationComponent;