import React, { useState, useEffect,useRef  } from 'react';
import { Button,Grid,  RadioGroup,Radio,FormControlLabel,TextField, FormLabel, Checkbox } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useLocation, useNavigate,useSearchParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Document, Page, Outline } from 'react-pdf/dist/esm/entry.webpack5';
import {apiDocument} from '../../services/api/documents/Document';  
import { apiProvider } from '../../services/api/utilities/Provider';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Draggable, {DraggableCore} from 'react-draggable';
import SignatureCanvas from 'react-signature-canvas';
import {isMobile,useMobileOrientation } from 'react-device-detect';
import {authVerify} from "../../services/api/utilities/AuthVerify";
import { useTranslation } from "react-i18next";

const SignComponent = (props) => {
    const { register, handleSubmit, reset, getValues, setValue } = useForm();
    const [validationMessage, setValidationMessage] = useState('');
    const [isBlur, setIsBlur] = useState(false);
    const [isClickSign, setIsClickSign] = useState(false);
    const [isNoSign, setIsNoSign] = useState(false);
    const [isR2T, setIsR2T] = useState(false);
    const [isTheirUser, setIsTheirUser] = useState(false);
    const [model, setModel] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [file, setFile] = useState(1);
    const [showMoreIndex, setShowMoreIndex] = useState(-1);
    const [fileName, setFileName] = useState('');
    const [pesel, setPesel] = useState('');
    const [items, setItems] = useState([]);
    const [fieldsText, setFieldsText] = useState([]);
    const [surveysText, setSurveysText] = useState([]);
    const [allFieldsText, setAllFieldsText] = useState([]);
    const [myItems, setMyItems] = useState([]);
    const [theirItems, setTheirItems] = useState([]);
    const [open, setOpen] = useState(false);
    const [openSign, setOpenSign] = useState(false);
    const [openChangeLandscape, setOpenChangeLandscape] = useState(false);
    const [openPermitedDocument, setOpenPermitedDocument] = useState(false);
    const [searchParams] = useSearchParams();
    const [sigCanvas,setSigCanvas] = useState({});
    const [sigCanvasMobile,setSigCanvasMobile] = useState({});
    const [sigCanvasMobileLandscape,setSigCanvasMobileLandscape] = useState({});
    const [signNumbers, setSignNumbers] = useState(0);
    const [signedNumbers, setSignedNumbers] = useState(0);
    const {isLandscape} = useMobileOrientation();
    const [isLoading, setIsLoading] = useState(false);
    const [uuid, setUUID] = useState('');
    const [wasSend, setWasSend] = useState(false);
    const [isLoadingBlue, setIsLoadingBlue] = useState(true);
    const { t, i18n, ready } = useTranslation();
    const signRef = useRef();
    const borderFirstRef = useRef();
    const borderSecondRef = useRef();

    const send = async () => {
        if(!isLoading)
        {
            setIsLoading(true);
            let file = props.file;
            if(props.templateId)
            {
                const template = await apiDocument.getTemplate(props.templateId);
                if(template)
                {
                    file = {fileName: template.name};
                }   
            }
            let document = {name: props.model.name, number: props.model.number, fileName: file.fileName, signOrder: props.model.signOrder, signMyOrder: props.model.signMyOrder, signTheirOrder: props.model.signTheirOrder, isAllPage: props.model.isAllPage, isFreeSort: true, isMarkers: props.model.isMarkers, templateId: props.templateId, isTemplate:  props.templateId};
            document.items = [];
            for (let i = 0 ; i < myItems.length; i++) {
                document.items.push({number: (i+1).toString(),firstName: myItems[i].firstName, lastName: myItems[i].lastName, email: myItems[i].email, companyName: myItems[i].companyName, companyNumber: myItems[i].companyNumber, isKIR: myItems[i].isKIR });
                document.items[document.items.length-1].signs = [];
                let signs = items.filter(d=>d.email==myItems[i].email);
                for(let y=0;y<signs.length;y++)
                {
                    document.items[document.items.length-1].signs.push({x:signs[y].x, y: signs[y].y, width:220, height: 60, page: signs[y].page});
                }
                //document.items[document.items.length-1].signs.push({x: , y: });
            }
            for (let i = 0 ; i < theirItems.length; i++) {
                document.items.push({number: (i+1).toString(),firstName: theirItems[i].firstName, lastName: theirItems[i].lastName, email: theirItems[i].email, companyName: theirItems[i].companyName, companyNumber: theirItems[i].companyNumber, isTheir: true, isKIR: theirItems[i].isKIR});
                document.items[document.items.length-1].signs = [];
                let signs = items.filter(d=>d.email==theirItems[i].email);
                for(let y=0;y<signs.length;y++)
                {
                    document.items[document.items.length-1].signs.push({x:signs[y].x, y: signs[y].y, width:220, height: 60, page: signs[y].page});
                }
                //document.items[document.items.length-1].signs.push({x: , y: });
            }
            let savedDocument = await apiDocument.save(document);
            if(savedDocument.id)
            {
                document.id = savedDocument.id;
                let sendedDocument = await apiDocument.send(document);
                if(sendedDocument!=null)
                {
                    window.location.href = "/index";
                }
            }
            else if(savedDocument.documents.length>0)
            {
                for(let index = 0; index<savedDocument.documents.length;index++)
                {
                    document.id = savedDocument.documents[index].id;
                    await apiDocument.send(savedDocument.documents[index]);
                }
                window.location.href = "/index";
            }
        }
    }

    const signproc = async() => {
        if(!model.isAllPage)
        {
            if(model.isToSigned>0 && model.isLocal)
                window.location.href = "/sign?id="+model.id;
            else
                window.location.href = "/index";
        }
        else
        {
            let signed = signedNumbers+1;
            setSignedNumbers(signed);
            console.log(signed+' '+signNumbers);
            if(signed>=signNumbers)
            {
                if(model.isToSigned>0 && model.isLocal)
                    window.location.href = "/sign?id="+model.id;
                else
                    window.location.href = "/index";
            }
            else
            {
                if(isMobile && isLandscape)
                    sigCanvasMobileLandscape.clear();
                else if(isMobile)
                    sigCanvasMobile.clear();
                else
                    sigCanvas.clear();
            }

        }
    }

    const sign = async () => {

        if(!isLoading)
        {
            /*if (window.screen.orientation) {
                // Get the current orientation
                const currentOrientation = window.screen.orientation.type;
          
                // Lock the orientation to the current one
                window.screen.orientation.lock(currentOrientation).catch((err) => {
                  console.error('Failed to lock orientation:', err);
                });
              } else {
                console.warn('Screen Orientation API is not supported');
            }*/
            setIsLoading(true);
            let body='';
            let template = null;
            if(model.templateId)
            {
                template = await apiDocument.getTemplate(model.templateId);
                if(template)
                {
                    body = template.body;
                    for(let i=0;i<fieldsText.length;i++)
                    {
                        let val = getValues('fields'+i);
                        body = body.replaceAll('##SignsafeField:'+fieldsText[i].field+"##",val);
                        body = body.replaceAll('##SignsafePESELField:'+fieldsText[i].field+"##",val);
                        body = body.replaceAll('##SignsafeDateField:'+fieldsText[i].field+"##",val);
                    }
                    let searchText = '<p>##SignsafeSurvey:';
                    let position=0;
                    position = body.indexOf(searchText, position);
                    let index =0;
                    while(position>=0)
                    {
                        let survey = body.substring(position,body.indexOf('##SignsafeSurvey##',position+1)+'##SignsafeSurvey'.length+6);
                        let answer = '<p>'+surveysText[index].field+'</p>';
                        if(surveysText[index].styleText===1)
                            answer = '<h1>'+surveysText[index].field+'</h1>';
                        if(surveysText[index].styleText===2)
                            answer = '<h2>'+surveysText[index].field+'</h2>';
                        if(surveysText[index].styleText===3)
                            answer = '<h3>'+surveysText[index].field+'</h3>';
                        if(surveysText[index].styleText===4)
                            answer = '<strong>'+surveysText[index].field+'</strong>';

                        let indexAnswer = 0;
                        surveysText[index].answers.forEach(element => {
                            if(surveysText[index].questionType==='1')
                            {
                                if(parseInt(surveysText[index].value)==indexAnswer)
                                    answer+='<p>[x] - '+element.field+'</p>';
                                else
                                    answer+='<p>[ ] - '+element.field+'</p>';
                            }
                            else
                            {
                                if(element.isChecked)
                                    answer+='<p>[x] - '+element.field+'</p>';
                                else
                                    answer+='<p>[ ] - '+element.field+'</p>';
                            }

                            indexAnswer++;
                        });
                        body = body.replaceAll(survey,answer);
                        position = body.indexOf(searchText, 0);
                        index++;
                    }
                }
            }
            if(!model.templateId || template)
            {
                if(isMobile && isLandscape)
                {
                    let result = await apiDocument.sign({ id: model.id, signature: sigCanvasMobileLandscape.toDataURL(), number: signedNumbers, body: body, pesel: pesel, fields: allFieldsText, uuid: uuid});
                    if(result!=null)
                    {
                        unlockOrientation();
                        signproc();
                        setIsLoading(false);
                        setWasSend(true);
                    }
                }
                else if(isMobile)
                {
                    let result = await apiDocument.sign({ id: model.id, signature: sigCanvasMobile.toDataURL(), number: signedNumbers, body: body, pesel: pesel, fields: allFieldsText, uuid: uuid });
                    if(result!=null)
                    {
                        unlockOrientation();
                        signproc();
                        setIsLoading(false);
                        setWasSend(true);
                    }
                }
                else
                {
                    let result = await apiDocument.sign({ id: model.id, signature: sigCanvas.toDataURL(), number: signedNumbers, body: body, pesel: pesel , fields: allFieldsText, uuid: uuid});
                    if(result!=null)
                    {
                        unlockOrientation();
                        signproc();
                        setIsLoading(false);
                        setWasSend(true);
                    }
                }
            }
        }
    }
    
    const unlockOrientation = ()=>{
        /*if (window.screen.orientation) {
            window.screen.orientation.unlock();
        }*/
    }

    const downloadFile = () => {
        apiDocument.openUrl(`/api/document/file/${model.id}`);
    };

    const downloadSummaryFile = () => {
        window.open(`${apiProvider.BASE_URL}${model.summaryURL}`, '_blank');
    };

    const handleClickOpen = () => {
        if(props.model!==null && !props.model.isMarkers)
        {
            for (let i = 0 ; i < myItems.length; i++) {
                let signs = items.filter(d=>d.email==myItems[i].email);
                if(signs.length<=0)
                {
                    setIsBlur(true);
                    setIsNoSign(true);
                    return;
                }
            }
            for (let i = 0 ; i < theirItems.length; i++) {
                let signs = items.filter(d=>d.email==theirItems[i].email);
                if(signs.length<=0)
                {
                    setIsBlur(true);
                    setIsNoSign(true);
                    return;
                }
            }
        }
        setOpen(true);
        setIsBlur(true);
    };

    const changeSurvey = (value, index) =>{
        surveysText[index].value = value;
        setItems([...items]);
    }

    const handleClose = () => {
        setIsClickSign(false);
        setOpen(false);
        setOpenSign(false);
        setIsBlur(false);
        setIsClickSign(false);
    };

    const handleClear = () => {
        setIsClickSign(false);
        if(isMobile && isLandscape)
            sigCanvasMobileLandscape.clear();
        else if(isMobile)
            sigCanvasMobile.clear();
        else
            sigCanvas.clear();
    };

    const onDocumentLoadSuccess = ({ numPages }) =>  {
        setIsLoadingBlue(false);
        setNumPages(numPages);
    }

    const setSign = (row, index) => {        
        if((model===null || model.id<=0) && props.model!==null && !props.model.isMarkers)
        {
            items.push({email: row.email, firstName: row.firstName, lastName: row.lastName, companyName: row.companyName, companyNumber: row.companyNumber, y:0, x:0, index: index, page: pageNumber});
            setItems([...items]);
        }
    }

    const removeAll = (index) => {
        for(let i=0;i<items.length;i++)
        {
            if(items[i].index===index)
            {
                items.splice(i, 1);
                i--;
                continue;
            }
        }
        setItems([...items]);
    }

    const removeSign = (row) => {
        let index = items.indexOf(row);
        items.splice(index, 1);
        setItems([...items]);
    }

    const copySign = (row) => {
        let index = items.indexOf(row);
        for(let i=pageNumber;i<numPages+1;i++)
        {
            if(pageNumber!==i)
                items.push({email: row.email, firstName: row.firstName, lastName: row.lastName, companyName: row.companyName, companyNumber: row.companyNumber, y:row.y, x:row.x, index: row.index, page: i});
        }
        setItems([...items]);
    }

    const handleStop = (e,data,row) => {
        console.log(data);
        let index = items.indexOf(row);
        items[index].x = parseInt(data.x);
        items[index].y = parseInt(data.y);
        console.log(items[index]);
        setItems([...items]);
    }

    let location = useLocation();
    let navigate = useNavigate();

    useEffect(() => {
        if(!isLoading)
        {
            if (location.search.indexOf('id=') >= 0) {
                let id = searchParams.get('id');
                localStorage.setItem('documentId', id);
            }
            authVerify.verifyUser();
            if(openSign && isMobile && isLandscape){
                if(!sigCanvasMobile.isEmpty())
                {
                    setOpenSign(false);
                    setOpenChangeLandscape(true);
                }
            }
            else if(openSign && isMobile && !isLandscape){
                if(!sigCanvasMobileLandscape.isEmpty())
                {
                    setOpenSign(false);
                    setOpenChangeLandscape(true);
                }
            }
        }
    }, [isLandscape])

    useEffect(() => {
        
        window.onpageshow = function(event) {
            if (event.persisted) {
                window.location.reload();
            }
        };

        async function fetchData() {
            if (location.search.indexOf('id=') >= 0) {
                if(wasSend)
                {
                    window.location.reload();
                    return;   
                }
                let document = await apiDocument.get(searchParams.get('id'));
                if(document!==null && document.perimited)
                {
                    setOpenPermitedDocument(true);
                    setIsLoading(false);
                    return;
                }

                if(document!==null)
                {
                    setIsR2T(document.isR2T)
                    setModel({id:document.id, name: document.name, sendDate: document.sendDateName, summaryURL: document.summaryURL, isToSigned: document.isToSigned, isMySigned: document.isMySigned, isMy: document.isMy, isAllPage: document.isAllPage, signOrder:document.signOrder, templateId: document.templateId, isFilled: document.isFilled, isLocal: document.isLocal, signItems: document.signItems});
                    if(document.items!==null)
                    {
                        setMyItems(document.items.filter(d=>!d.isTheir));
                        setTheirItems(document.items.filter(d=>d.isTheir));
                        let email = localStorage.getItem('email');
                        setIsTheirUser(document.items.filter(d=>d.isTheir && d.email==email).length>0);
                        items.length=0;
                        (document.signItems).forEach(element => {
                            if(!element.item.isSigned)
                            {
                                let itemsToSigns =document.items.filter(d=>!d.isTheir);
                                
                                let index = itemsToSigns.indexOf(itemsToSigns.filter(d=>d.email===element.item.email)[0]);
                                if(index<0)
                                {
                                    itemsToSigns =document.items.filter(d=>d.isTheir);
                                    index = itemsToSigns.indexOf(itemsToSigns.filter(d=>d.email===element.item.email)[0])+4;
                                }
                                items.push({email: element.item.email, firstName: element.item.firstName, lastName: element.item.lastName, companyName: element.item.companyName, companyNumber: element.item.companyNumber, y:element.y, x:element.x, index: index, page: element.page});
                            }
                        });
                        setItems([...items]);
                    }
                    let file = await apiDocument.downloadFile(document.fileURL);
                    if(file!==null && file.file64!==null)
                    {
                        setFile('data:application/pdf;base64,'+file.file64);
                    }
                    setFileName(document.fileURL);
                    if(document.isToSigned>0 && !document.isMySigned && document.isAllPage)
                    {
                        let email = localStorage.getItem('email');
                        setSignNumbers(document.signItems.filter(d=>d.item.email===email).length);
                    }
                    
                    if(document.templateId)
                    {
                        const template = await apiDocument.getTemplate(document.templateId);
                        if(template)
                        {
                            let body = template.body;
                            let searchText = '##SignsafeField:';
                            let position = 0;
                            position = body.indexOf(searchText, position);
                            let fields = [];
                            while(position>=0)
                            {
                                fields.push({field: body.substring(position+searchText.length,body.indexOf('##',position+1))});
                                position = body.indexOf(searchText, position+1);
                            }
                            setFieldsText(fields);
                            searchText = '##SignsafePESELField:';
                            position = 0;
                            position = body.indexOf(searchText, position);
                            while(position>=0)
                            {
                                fields.push({field: body.substring(position+searchText.length,body.indexOf('##',position+1)), isPESEL: true});
                                position = body.indexOf(searchText, position+1);
                            }
                            setFieldsText(fields);

                            searchText = '##SignsafeDateField:';
                            position = 0;
                            position = body.indexOf(searchText, position);
                            while(position>=0)
                            {
                                fields.push({field: body.substring(position+searchText.length,body.indexOf('##',position+1)), isDate: true});
                                position = body.indexOf(searchText, position+1);
                            }
                            setFieldsText(fields);
                            searchText = '##SignsafeSurvey:';
                            position=0;
                            position = body.indexOf(searchText, position);
                            let surveys = [];
                            while(position>=0)
                            {
                                let survey = body.substring(position+searchText.length,body.indexOf('##SignsafeSurvey',position+1)).replaceAll('##</p>','');
                                let styleText = 0;
                                if(survey.indexOf('<h1>')>=0)
                                    styleText=1;
                                if(survey.indexOf('<h2>')>=0)
                                    styleText=2;
                                if(survey.indexOf('<h3>')>=0)
                                    styleText=3;
                                if(survey.indexOf('<strong>')>=0)
                                    styleText=4;
                                let text = survey.substring(survey.indexOf('>')+1,survey.indexOf('</'));
                                let questionType = survey.substring(0,survey.indexOf('<'));
                                let searchTextAnswer = '<li';
                                let positionAnswer = survey.indexOf('<li', 0);
                                let answers = [];
                                while(positionAnswer>=0)
                                {
                                    let answer = survey.substring(positionAnswer+searchTextAnswer.length, survey.indexOf('</li>', positionAnswer+1));
                                    answers.push({field: answer.replaceAll('class="ql-align-center">','').replaceAll('>','')});
                                    positionAnswer = survey.indexOf('<li', positionAnswer+searchTextAnswer.length);
                                }
                                surveys.push({field: text, answers: answers, questionType:questionType, value: 0, styleText: styleText});
                                position = body.indexOf(searchText, position+survey.length+'##SignsafeSurvey:1##</p>'.length);
                            }
                            setSurveysText(surveys);

                            searchText = '##';
                            position = 0;
                            position = body.indexOf(searchText, position);
                            let allFields = [];
                            let indexSurvey=0;
                            let indexField=0;
                            let indexAll=0;
                            while(position>=0)
                            {
                                let fieldName = body.substring(position+searchText.length,body.indexOf('##',position+1));
                                if(fieldName.indexOf('SignsafeSurvey')>=0)
                                {
                                    if(indexSurvey<surveys.length)
                                        allFields.push({field:surveys[indexSurvey].field, index: indexSurvey, isSurvey: true});
                                    indexSurvey++;
                                    position+=10;
                                    position=body.indexOf('##SignsafeSurvey',position);
                                    position=body.indexOf('##',position+1);
                                }
                                else
                                {
                                    let field = body.substring(position+searchText.length,body.indexOf('##',position+1)).replace("SignsafePESELField:","").replace("SignsafeDateField:","").replace("SignsafeField:","");
                                    field = fields.filter(d=>d.field==field)[0];
                                    if(field)
                                        allFields.push({field:field.field, index: fields.indexOf(field), isSurvey: false, isPESEL: field.isPESEL, isDate: field.isDate});
                                    indexField++;
                                    position+=10;
                                    position=body.indexOf('##',position+1);
                                }
                                position = body.indexOf(searchText, position+1);
                                indexAll++;
                            }
                            setAllFieldsText(allFields);
                        }
                    }
                }
            }
            else
            {
                if(props.templateId)
                {
                    const template = await apiDocument.getTemplate(props.templateId);
                    if(template)
                    {
                        let body = template.body;
                        let searchText = '##SignsafeField:';
                        let position = 0 ;
                        position = body.indexOf(searchText, position);
                        let fields = [];
                        while(position>=0)
                        {
                            fields.push({field: body.substring(position+searchText.length,body.indexOf('##',position+1))});
                            position = body.indexOf(searchText, position+1);
                        }
                        setFieldsText(fields);
                        searchText = '##SignsafePESELField:';
                        position = 0;
                        position = body.indexOf(searchText, position);
                        while(position>=0)
                        {
                            fields.push({field: body.substring(position+searchText.length,body.indexOf('##',position+1)), isPESEL: true});
                            position = body.indexOf(searchText, position+1);
                        }
                        setFieldsText(fields);

                        searchText = '##SignsafeDateField:';
                        position = 0;
                        position = body.indexOf(searchText, position);
                        while(position>=0)
                        {
                            fields.push({field: body.substring(position+searchText.length,body.indexOf('##',position+1)), isDate: true});
                            position = body.indexOf(searchText, position+1);
                        }
                        setFieldsText(fields);
                        const result = await apiDocument.generateDocument({htmlData: template.body})
                        if(result)
                        {
                            let file = await apiDocument.downloadFile(result.path);
                            setFile('data:application/pdf;base64,'+file.file64);
                            setFileName(template.name);
                        }
                    }
                }
                else
                {
                    let file = await apiDocument.downloadFile(props.file.fileName);
                    setFile('data:application/pdf;base64,'+file.file64);
                    setFileName(props.file.fileName);
                }
                if(props!==null && props.myItems!==null)
                {
                    setMyItems(props.myItems);
                    setTheirItems(props.theirItems);
                }
            }
        }
        fetchData();
    }, []);

    const handleMySort = (e, data, row) => {
		//duplicate items
		let _myItems = [...myItems]

		//remove and save the dragged item content
		const draggedItemContent = _myItems.splice(dragItem.current, 1)[0]

		//switch the position
		_myItems.splice(dragOverItem.current, 0, draggedItemContent)

		//reset the position ref
		dragItem.current = null
		dragOverItem.current = null

		//update the actual array
		setMyItems(_myItems)
	}

    const handleTheirSort = (e, data, row) => {
		//duplicate items
		let _theirItems = [...theirItems]

		//remove and save the dragged item content
		const draggedItemContent = _theirItems.splice(dragItem.current, 1)[0]

		//switch the position
		_theirItems.splice(dragOverItem.current, 0, draggedItemContent)

		//reset the position ref
		dragItem.current = null
		dragOverItem.current = null

		//update the actual array
		setTheirItems(_theirItems)
	}

    const setIsAllPage = (value) => {
        props.model.isAllPage = value==="1";
    }

    const dragItem = React.useRef(null)
	const dragOverItem = React.useRef(null)

    const logOff = () =>{
        localStorage.setItem('token', '');
        localStorage.setItem('fullName', '');
        localStorage.setItem('role', '');
        localStorage.setItem('email', '');
        localStorage.setItem('avatar', '');
        window.location.href = "/";
    }

    const handlePESEL = (value, row) => {
        let checkbox = getValues('fieldsCheck'+row.index);
        if(row.isPESEL && !checkbox){
            let result = value.replace(/\D/g, '');
            if(result.length>11)
                result = result.substring(0,11);
            setValue('fields'+row.index,result);
            setPesel(result);
        }
        else if(row.isPESEL)
        {
            setPesel(value);
        }

        if(allFieldsText.indexOf(row)>=0)
        {
            row.fieldValue = value;
        }
    };

    const runSign = async (uuid) => {
        //window.kir.load();
        setUUID(uuid);
        if(uuid)
        {
            if(model.isToSigned>0 && !model.isMySigned && model.isAllPage)
            {
                let email = theirItems.filter(d=>d.uuid == uuid);
                if(email.length>0)
                    setSignNumbers(model.signItems.filter(d=>d.item.email===email[0].email).length);
            }
        }
        window.scrollTo(0, 0);
        if(!model.isFilled)
        {
            for(let i=0;i<fieldsText.length;i++)
            {
                let val = getValues('fields'+i);
                if(!val || val==='')
                {
                    setValidationMessage('Pole "'+fieldsText[i].field+'" jest wymagane');
                    return;
                }
                
                if(fieldsText[i].isPESEL && val.length!=11 && allFieldsText.filter(d=>d.isPESELValidationNotChecked).length==0)
                {
                    setValidationMessage('Pole "'+fieldsText[i].field+'" powinno mieć 11 znaków');
                    return;
                }
            }
        }
        setOpenSign(true);
    }

    const zoom = async () =>{
        if(!signRef.current.classList.contains('zoom2x'))
        {
            signRef.current.classList.add('zoom2x');
            borderFirstRef.current.classList.add('border-first-full-width');
            borderSecondRef.current.classList.add('border-second-full-width');
        }
        else
        {
            signRef.current.classList.remove('zoom2x');
            borderFirstRef.current.classList.remove('border-first-full-width');
            borderSecondRef.current.classList.remove('border-second-full-width');
        }
    }

    const changeCheck = async (e,row)=>{

        for(let i=0;i<allFieldsText.length;i++)
        {
            if(allFieldsText[i].index===row.index)
                allFieldsText[i].isPESELValidationNotChecked=e.target.checked;   
        }
        
        setAllFieldsText([...allFieldsText]);
    }

    return (
        <div>
            {isNoSign && 
                <div className="popup">
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={11}>
                                    <h5>{t("sign_text1a")}</h5>
                                </Grid>
                                <Grid item xs={1} className="right-element">
                                    <a className="x" onClick={()=>{setIsNoSign(false);setIsBlur(false);}}></a>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={()=>{setIsNoSign(false);setIsBlur(false);}}>{t("sign_text1b")}</Button>
                        </Grid>
                    </Grid>
                </div>
            }
            <div className={`${!isLoadingBlue ? "not-show":''}`}>
                <Grid container className="padding-body">
                    <div className="loader-blue"></div>
                </Grid>
            </div>
            <div className={`main-body sign-body ${ isBlur ? "blur":''} ${isLoadingBlue ? "not-show":''}`}>
                <Grid container>
                    <Grid item xs={12} alignItems="center" justifyContent="center">
                        {model!==null && <h2>{t("sign_text1")}</h2>}
                        {model===null && <h2>{t("sign_text2")}</h2>}
                    </Grid>
                </Grid>
                <Grid container className="border-grid">
                    <Grid item xs={6} className="border-first" ref={borderFirstRef}>
                        <div>
                            <div className="sign-box" ref={signRef}>
                                <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page pageNumber={pageNumber} width={484}/>
                                </Document>
                                {items.map((row, index) => {
                                return(row.page===pageNumber && !isLoadingBlue && <Draggable position={{x:row.x, y: row.y}} 
                                    bounds="parent" disabled={location.search.indexOf('id=') >= 0} onStop={(e,data)=>handleStop(e,data,row)}>
                                    <div className={`person-sign ${"person-sign"+(location.search.indexOf('id=') >= 0 ? "-back":"")+(row.index)}`}>
                                        {location.search.indexOf('id=') < 0 && 
                                            <div>
                                                <a className="copy" onClick={()=>copySign(row)}></a>
                                                <a className="remove" onClick={()=>removeSign(row)}></a>
                                            </div>
                                        }
                                        <span className="person-name">{row.firstName+' '+row.lastName}</span>
                                    </div>
                                </Draggable>);
                                })}
                            </div>
                            <Grid container className="center-page">
                                <Grid item style={{width:"30px"}}><a className='first' onClick={()=>setPageNumber(1)}></a></Grid>
                                <Grid item style={{width:"30px"}}><a className='prev' onClick={()=>setPageNumber(pageNumber-1 >=1 ? pageNumber-1 : 1)}></a></Grid>
                                <Grid item style={{width:"30px"}}><a className='next' onClick={()=>setPageNumber(pageNumber+1<=numPages ? pageNumber+1:numPages)}></a></Grid>
                                <Grid item style={{width:"30px"}}><a className='last' onClick={()=>setPageNumber(numPages)}></a></Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs></Grid>
                                <Grid item xs={1} className='not-mobile'><a className='full-eye-item' onClick={()=>zoom()}><i className='eye'></i></a></Grid>
                                <Grid item xs={1} className='only-mobile'><a className='full-eye-item' onClick={()=>downloadFile()}><i className='eye'></i></a></Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={6} className="border-second" ref={borderSecondRef}>
                        <div className="sign-box2">
                            <Grid container>
                                <Grid direction="row" xs className="signers-box">
                                    {model!==null && 
                                        <div>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <p className="sign-document-label">{t("sign_text3")}</p>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <p className="sign-document-label">{t("sign_text4")}</p>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <p className="sign-document-name">{model.sendDate}</p>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <p className="sign-document-name">{model.name}</p>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    }
                                    {(model===null || model.id<=0) && props.model!=null && 
                                        <div>
                                            <Grid item xs={12}>
                                                <p className="sign-document-label">{t("sign_text5")}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p className="sign-document-name">{props.model.name}</p>
                                            </Grid>
                                        </div>
                                    }
                                    {(model===null || model.id<=0) && props.model!=null && !props.model.isMarkers && 
                                        <Grid container className="info">
                                            <Grid item style={{width:"30px"}}>
                                                <i className="info-svg"></i>
                                            </Grid>
                                            <Grid item xs>
                                                <p>{t("sign_text6b")}</p>
                                            </Grid>
                                        </Grid>
                                    }
                                    {model!==null && !isTheirUser && 
                                        <div>
                                            <Grid item xs={12}>
                                                <p className="sign-document-label">{t("sign_text6")}</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p className="sign-document-name">{model!==undefined && model.signOrder===1 ? t("sign_text9"):(model!==undefined && model.signOrder===2 ? t("sign_text7"):t("sign_text8"))}</p>
                                            </Grid>
                                        </div>
                                    }
                                    {(!isTheirUser || !isR2T) &&
                                        <div>
                                            {model!==null && 
                                                <Grid container>
                                                    <Grid item xs={10}>
                                                        <p className="sign-document-label">{t("sign_text10")} &nbsp;
                                                        <span className="sign-document-name">{t("sign_text11")}</span></p>
                                                    </Grid>
                                                </Grid>
                                            }
                                            {myItems.map((row, index) => {
                                                return(<Grid item xs={12} className={`box-item ${"box-item"+index}`}>
                                                    {model===null && 
                                                        <Grid container draggable
                                                        onDragStart={(e) => (dragItem.current = index)}
                                                        onDragEnter={(e) => (dragOverItem.current = index)}
                                                        onDragEnd={handleMySort}
                                                        onDragOver={(e) => e.preventDefault()}>
                                                            <Grid item xs={1} onClick={()=>setSign(row, index)}>
                                                                <a className="edit-icon"></a>
                                                            </Grid>
                                                            <Grid item xs={9} onClick={()=>setSign(row, index)}>
                                                                <p className='box-item-margin'>{row.firstName+' '+row.lastName+'-'+row.companyName+'-'+row.companyNumber}<br/>{row.email}</p>
                                                            </Grid>
                                                            <Grid item xs={1} className="right-element" onClick={()=>setSign(row, index)}>
                                                                <p style={{marginTop:"12px"}}>({items.filter(d=>d.email==row.email).length})</p>
                                                            </Grid>
                                                            <Grid item xs className="right-element" style={{width:"20px"}} onClick={()=>setShowMoreIndex((index===showMoreIndex ? -1:index))}>
                                                                <a className="dots"></a>
                                                            </Grid>
                                                            {showMoreIndex===index && <Grid item xs={12} className="right-element">
                                                                    <a className='dot-href' onClick={()=>removeAll(index)}>{t("sign_text12")}</a>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    }
                                                    {model!==null && 
                                                        <Grid container>
                                                            <Grid item xs={8}>
                                                                <p className='box-item-margin'>{row.firstName+' '+row.lastName+'-'+row.companyName+'-'+row.companyNumber}<br/>{row.email}</p>
                                                            </Grid>
                                                            {row.signDateName!==null && row.signDateName!=='' && 
                                                                <Grid item xs={3}>
                                                                    <p className='box-item-margin center'>{t("sign_text13")}<br/>{row.signDateName}</p>
                                                                </Grid>
                                                            }
                                                            {(row.signDateName===null || row.signDateName==='') && 
                                                                <Grid item xs={3}>
                                                                    
                                                                </Grid>
                                                            }
                                                            <Grid item xs={1} className="right-element">
                                                                <a className="dots"></a>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>);
                                            })}
                                        </div>
                                    }
                                    {theirItems.length>0 && 
                                        <Grid container>
                                            <Grid item xs={6}>
                                                {!isTheirUser && <p><span className="number">3. </span>{t("sign_text14")} 
                                                {(theirItems.filter(e=>items.filter(d=>d.email==e.email).length>0).length==theirItems.length || (props.model &&  props.model.isMarkers)) && <i className='approve'></i>}
                                                {(theirItems.filter(e=>items.filter(d=>d.email==e.email).length>0).length!=theirItems.length && (!props.model || !props.model.isMarkers)) && <i className='approve2'></i>}
                                                </p>}
                                                {isTheirUser && <h4>{t("sign_text14b")}</h4>}
                                            </Grid>
                                        </Grid>
                                    }
                                    {theirItems.length>0 && 
                                        <Grid container>
                                            <Grid item xs={10}>
                                                <p className="sign-document-label">{t("sign_text15")}:&nbsp;
                                                <span className="sign-document-name">{t("sign_text16")}</span></p>
                                            </Grid>
                                        </Grid>
                                    }
                                    {theirItems.map((row, index) => {
                                        return (<Grid item xs={12} className={`box-item ${"box-item"+(index+4)} `}>
                                            {model===null && 
                                                <Grid container draggable
                                                onDragStart={(e) => (dragItem.current = index)}
                                                onDragEnter={(e) => (dragOverItem.current = index)}
                                                onDragEnd={handleTheirSort}
                                                onDragOver={(e) => e.preventDefault()}>
                                                    <Grid item xs={1} onClick={()=>setSign(row,index+4)}>
                                                        {items.filter(d=>d.email==row.email).length<=0 && (!props.model || !props.model.isMarkers) && <a className="approve_sign"></a>}
                                                        {(items.filter(d=>d.email==row.email).length>0 || (props.model && props.model.isMarkers)) && <a className="approve_sign2"></a>}
                                                    </Grid>
                                                    <Grid item xs={9} onClick={()=>setSign(row,index+4)}>
                                                        <p className='box-item-margin'>{row.firstName+' '+row.lastName+(row.companyName!=='' ? '-'+row.companyName:'')+'-'+row.companyNumber}<br/><span>{row.email}</span></p>
                                                    </Grid>
                                                    {!props.templateId && (!props.model || !props.model.isMarkers) && 
                                                        <Grid item xs={1} className="right-element" onClick={()=>setSign(row,index+4)}>
                                                            <p style={{marginTop:"12px"}}>({items.filter(d=>d.email==row.email).length})</p>
                                                        </Grid>
                                                    }
                                                    {(props.templateId || (props.model && props.model.isMarkers)) && 
                                                        <Grid item xs={1} className="right-element">
                                                            
                                                        </Grid>
                                                    }
                                                    <Grid item xs className="right-element" style={{width:"20px"}} onClick={()=>setShowMoreIndex((index+4===showMoreIndex ? -1:(index+4)))}>
                                                        <a className="dots"></a>
                                                    </Grid>
                                                    {showMoreIndex===index+4 && <Grid item xs={12} className="right-element">
                                                            <a className='dot-href' onClick={()=>removeAll(index+4)}>{t("sign_text17")}</a>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            }
                                            {model!==null && 
                                                <Grid container>
                                                    <Grid item xs={8}>
                                                        <p className='box-item-margin'>{row.firstName+' '+row.lastName+(row.companyName!=='' ? '-'+row.companyName:'')+'-'+row.companyNumber}<br/>{row.email}</p>
                                                    </Grid>
                                                    {row.signDateName!==null && row.signDateName!=='' && 
                                                        <Grid item xs={3}>
                                                            <p className='box-item-margin center'>{t("sign_text18")}<br/>{row.signDateName}</p>
                                                        </Grid>
                                                    }
                                                    {(row.signDateName===null || row.signDateName==='') && 
                                                        <Grid item xs={3}>
                                                            {model.isLocal && model.isToSigned>0 && 
                                                                <Button className="button full-width signer button-little-small mt-10b" full-width onClick={()=>runSign(row.uuid)}><i className="edit-sign-icon"></i>{t("sign_text28")}</Button>
                                                            }  
                                                        </Grid>
                                                    }
                                                    <Grid item xs={1} className="right-element">
                                                        <a className="dots"></a>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>);
                                    })}
                                    {model!==null && model.isMySigned && 
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <h4 className="center">{t("sign_text19")}</h4>
                                            </Grid>
                                            <Grid item xs={12} style={{marginBottom:'10px'}}>
                                                <Button className="button button-white center-item" full-width onClick={()=>downloadSummaryFile()}><i className="download-white-icon"></i>&nbsp;&nbsp;{t("sign_text20")}</Button>
                                            </Grid>
                                        </Grid>
                                    }
                                    {model!==null && model.isToSigned>0 && !model.isFilled && (fieldsText.length>0 || surveysText.length>0) && !model.isMy && 
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <h4>{t("sign_text19a")}</h4>
                                            </Grid>
                                        </Grid>    
                                    }
                                    {validationMessage && <Grid container>
                                        <div className="validation-error">{validationMessage}</div>
                                        </Grid>
                                    }
                                    {model!==null && model.isToSigned>0 && !model.isFilled && 
                                        <Grid container className='survey'>
                                            {allFieldsText.map((row, index) => {
                                                return (<>{!row.isSurvey ? 
                                                    <Grid item xs={12}>
                                                    {row.isPESEL && <Grid item xs={12}>
                                                        <FormLabel>Brak</FormLabel>
                                                        <input type="checkbox" onClick={e=>changeCheck(e, row)} {...register("fieldsCheck"+row.index)}/>
                                                    </Grid>}
                                                    {row.isPESEL && row.isPESELValidationNotChecked && <FormLabel>Identyfikator</FormLabel>}
                                                    {(!row.isPESEL || !row.isPESELValidationNotChecked) && <FormLabel>{fieldsText[row.index].field}</FormLabel>}
                                                    <TextField autoComplete='off' required autoFocus {...register("fields"+row.index, { required: true })} onChange={(e)=>handlePESEL(e.target.value,row)} type={row.isDate ? 'date':'text'}/>
                                                    </Grid> : <Grid item xs={12}>
                                                    <FormLabel>{surveysText[row.index].field}</FormLabel>
                                                    {surveysText[row.index].questionType==='1' && 
                                                        <RadioGroup className="radio" defaultValue={0} onChange={(e)=>changeSurvey(e.target.value, row.index)}>
                                                            {surveysText[row.index].answers.map((survey, surveyIndex) => {
                                                                return (<FormControlLabel value={surveyIndex} control={<Radio />} label={survey.field} />)
                                                            })}
                                                        </RadioGroup>
                                                    }
                                                    {surveysText[row.index].questionType==='2' && 
                                                        <Grid item xs={12} className="radio">
                                                            {surveysText[row.index].answers.map((survey, surveyIndex) => {
                                                                return (<div><FormControlLabel control={<Checkbox checked={survey.isChecked} onChange={(e)=>survey.isChecked=e.target.value}/>} label={survey.field} /></div>)
                                                            })}
                                                        </Grid>
                                                    }
                                                </Grid>}</>);
                                            })}
                                        </Grid>
                                    }
                                    {model===null &&
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <p className="box-signers-radio-info">{t("sign_text21")}</p>
                                            </Grid>
                                            <Grid container>
                                                <RadioGroup className="radio" defaultValue={(props.model!==undefined && props.model.isAllPage ? "1":"2")} onChange={(event)=>setIsAllPage(event.target.value)}>
                                                    <FormControlLabel value="1" control={<Radio />} label={t("sign_text22")} />
                                                    <FormControlLabel value="2" control={<Radio />} label={t("sign_text23")} />
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                                <Grid item style={{height:"50px"}} xs={12} className="signers-box2">
                                    <Grid container spacing={2}>
                                        {model===null &&
                                            <Grid item xs={3}>
                                                <a onClick={()=>props.setIsSignComponent(false)} className="button button-cancel" fullWidth><i className="back not-mobile"></i>{t("sign_text24")}</a>
                                            </Grid>
                                        }
                                        {model!==null &&
                                            <Grid item xs={3}>
                                                <a onClick={()=>navigate(-1)} className="button button-cancel" fullWidth><i className="back not-mobile"></i><span>{t("sign_text25")}</span></a>
                                            </Grid>
                                        }
                                        {model===null &&
                                            <Grid item xs={9} style={{opacity: (theirItems.filter(e=>items.filter(d=>d.email==e.email).length>0).length==theirItems.length || (props.model && props.model.isMarkers)) ? 1:0.2}}>
                                                <Button disabled={(theirItems.filter(e=>items.filter(d=>d.email==e.email).length>0).length!=theirItems.length && (!props.model || !props.model.isMarkers))} className="button full-width" full-width onClick={()=>handleClickOpen()}>{t("sign_text26")}</Button>
                                            </Grid>
                                        }
                                        {model!==null && (model.isToSigned===0 || model.isMySigned) && 
                                            <Grid item xs={9}>
                                                <Button className="button full-width" full-width onClick={()=>downloadFile()}><i className="download-icon"></i>&nbsp;&nbsp;{t("sign_text27")}</Button>
                                            </Grid>
                                        }
                                        {model!==null && (model.isToSigned>0 && !model.isMySigned && !model.isLocal) &&
                                            <Grid item xs={9}>
                                                <Button className="button full-width signer" full-width onClick={()=>runSign()}><i className="edit-sign-icon"></i>&nbsp;&nbsp;{t("sign_text28")}</Button>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div>
            <Dialog open={open} onClose={handleClose} style={{padding:"10px"}}>
                <DialogContent>
                <DialogContentText>
                    {t("sign_text29")}
                </DialogContentText>
                </DialogContent>
                <DialogActions >
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <a onClick={handleClose} className="button button-cancel" fullWidth><i className="back not-mobile"></i>{t("sign_text30")}</a>
                        </Grid>
                        <Grid item xs>
                            <Button className="button full-width" onClick={()=>send()}>
                                {isLoading && 
                                    <span className="loader"></span>
                                }
                                {!isLoading && 
                                    <span>{t("sign_text31")}</span>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <Dialog open={openChangeLandscape} onClose={handleClose} style={{padding:"10px"}}>
                <DialogContent>
                    <DialogContentText>
                    {t("sign_text32b")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                        <Grid container spacing={2} className="right-side action-margin">
                            <Button className="button" onClick={()=>{setOpenSign(true);setOpenChangeLandscape(false);}}>
                                {t("sign_text32d")}
                            </Button>
                        </Grid>
                </DialogActions>
            </Dialog>
            <Dialog open={openPermitedDocument} style={{padding:"10px"}}>
                <DialogContent>
                    <DialogContentText>
                    {t("sign_text32c")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions >
                        <Grid container spacing={2} className="right-side action-margin">
                            <Button className="button" onClick={()=>{logOff();}}>
                                {t("sign_text32e")}
                            </Button>
                        </Grid>
                </DialogActions>
            </Dialog>
            <Dialog open={openSign} onClose={handleClose} style={{padding:"10px"}}>
                <div className="dialog-sign">
                    <DialogTitle>{t("sign_text32")}</DialogTitle>
                    <DialogContent>
                        {model!==null && model.isAllPage && <p className='sign-quantity'>{(signedNumbers+1)+'/'+signNumbers}</p>}
                        {!isMobile &&
                        <div>
                            <SignatureCanvas canvasProps={{width: 500, height: 300, className: 'sigCanvas'}} ref={(ref) => { setSigCanvas(ref) }} onBegin={()=>setIsClickSign(true)}/>
                        </div>
                        }
                        {isMobile && !isLandscape &&
                        <div>
                            <SignatureCanvas canvasProps={{width: window.innerWidth>700 ? (window.innerWidth-250):250, height:  model!==null && model.isAllPage ? 280:250, className: 'sigCanvas'}} ref={(ref) => { setSigCanvasMobile(ref) }} onBegin={()=>setIsClickSign(true)}/>
                        </div>
                        }
                        {isMobile && isLandscape &&
                        <div>
                            <SignatureCanvas canvasProps={{width: 400, height: 200, className: 'sigCanvas'}} ref={(ref) => { setSigCanvasMobileLandscape(ref) }} onBegin={()=>setIsClickSign(true)}/>
                        </div>
                        }
                    </DialogContent>
                    <DialogActions >
                        <Grid container spacing={2} className="right-side action-margin">
                            <a className="button button-cancel" fullWidth onClick={()=>handleClose()}>{t("sign_text33")}</a>&nbsp;&nbsp;
                            <a className="button button-cancel" fullWidth onClick={()=>handleClear()}>{t("sign_text34")}</a>&nbsp;&nbsp;
                            <Button className="button" onClick={()=>sign()} disabled={!isClickSign}>
                                {isLoading && 
                                    <span className="loader"></span>
                                }
                                {!isLoading && 
                                    <span>{t("sign_text35")}</span>
                                }
                            </Button>
                        </Grid>
                    </DialogActions>
                </div>
            </Dialog>
            </div>
        </div>
    );
}

export default SignComponent;