import axios from 'axios';
import { handleResponse, handleError } from '../utilities/Response';
import { apiProvider } from '../utilities/Provider';

export class ApiImageRepo {
    constructor() {
        this.search = async (queryParams) =>{
            return await axios
                .post(`${apiProvider.BASE_URL}/api/imageRepository/gets`, queryParams, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.save = async (model) =>{
            return await axios
                .post(`${apiProvider.BASE_URL}/api/imageRepository/add`, model, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.get = async (id) =>{
            return await axios
                .get(`${apiProvider.BASE_URL}/api/imageRepository/get?id=${id}`, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }

        this.remove = async (model) =>{
            return await axios
                .post(`${apiProvider.BASE_URL}/api/imageRepository/delete`, model, apiProvider.config)
                .then(handleResponse)
                .catch(handleError);
        }
    }
}

export const apiImageRepo = new ApiImageRepo({});