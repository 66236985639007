import React, { useState, useEffect } from 'react';
import { Button,Grid,RadioGroup, Radio,FormControlLabel,TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import {apiLogin} from '../../services/api/accounts/Login';
import { useTranslation } from "react-i18next";
import {apiDocument} from '../../services/api/documents/Document';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const PayLoadComponent = (props) => {
    const { register,  getValues,handleSubmit, setValue } = useForm();
    const { t, i18n, ready } = useTranslation();
    const [validationMessage, setValidationMessage] = useState('');
    const [formatDate, setFormatDate] = useState('');
    const [month, setMonth] = useState(-1);
    const [openDelete, setOpenDelete] = useState(false);
    const [currentUser, setUser] = useState({});
    const [option, setOption] = useState(1);
    const [editMode, setEditMode] = useState(false);

    const fetchData = async () => {
        let user = await apiLogin.getCurrentUser();
        if(user!==null)
        {
            if(user.company)
                setValue('companyName',user.company);

            if(user.name && user.surname)
                setValue('companyName',user.name+' '+user.surname);

            if(user.email && user.email)
                setValue('email',user.email);

            if(user.city && user.city)
                setValue('city',user.city);

            if(user.street && user.street)
                setValue('street',user.street);

            if(user.number && user.number)
                setValue('number1',user.number);

            if(user.number2 && user.number2)
                setValue('number2',user.number2);

            if(user.postalCode && user.postalCode)
                setValue('postalCode',user.postalCode);

            if(user.nip && user.nip)
                setValue('nip',user.nip);

            if(user.country && user.country)
                setValue('country',user.country);

            setUser(user);
        }
        
    }
    
    useEffect(() => {
        setFormatDate(new Date().toLocaleDateString("en-GB"))
        fetchData();
    }, []);

    const onSubmit = async (data) => {
        let companyName= getValues("companyName");
        if(!companyName)
        {
            setValidationMessage('Pole nazwa firmy jest wymagana');
            return;
        }
        //let isAccept= getValues("isAccept");
        //if(!isAccept)
        //{
            //setValidationMessage('Wymagane zgody nie zostały zaakceptowane: “"Przeczytałem i zrozumiałem wszystkie warunki umowy oraz politykę prywatności."');
            //return;
        //}
        let street= getValues("street");
        if(!street)
        {
            setValidationMessage('Pole Ulica jest wymagana');
            return;
        }
        let number1= getValues("number1");
        if(!number1)
        {
            setValidationMessage('Pole Nr domu jest wymagana');
            return;
        }
        let number2= getValues("number2");
        let postalCode= getValues("postalCode");
        if(!postalCode)
        {
            setValidationMessage('Pole Kod pocztowy jest wymagana');
            return;
        }
        let city= getValues("city");
        if(!city)
        {
            setValidationMessage('Pole Miasto jest wymagana');
            return;
        }
        let country= getValues("country");
        if(!country)
        {
            setValidationMessage('Pole Kraj jest wymagana');
            return;
        }
        let nip= getValues("nip");
        let result = await apiDocument.pay({companyName: companyName, companyNip: nip, companyStreet:street, companyNumber: number1, companyNumber2: number2, companyPostalCode: postalCode, companyCity: city, companyCountry: country, oneTime: option!=1, year: option==3});
        if(result.redirectUrl)
        {
            window.location.href=result.redirectUrl;
        }
    }

    const save = async () => {
        setValidationMessage('');
        let companyName= getValues("companyName");
        if(!companyName)
        {
            setValidationMessage('Pole nazwa firmy jest wymagana');
            return;
        }
        let street= getValues("street");
        if(!street)
        {
            setValidationMessage('Pole Ulica jest wymagana');
            return;
        }
        let number1= getValues("number1");
        if(!number1)
        {
            setValidationMessage('Pole Nr domu jest wymagana');
            return;
        }
        let number2= getValues("number2");
        let postalCode= getValues("postalCode");
        if(!postalCode)
        {
            setValidationMessage('Pole Kod pocztowy jest wymagana');
            return;
        }
        let city= getValues("city");
        if(!city)
        {
            setValidationMessage('Pole Miasto jest wymagana');
            return;
        }
        let country= getValues("country");
        if(!country)
        {
            setValidationMessage('Pole Kraj jest wymagana');
            return;
        }
        let nip= getValues("nip");
        if(nip && !validateNip(nip))
        {
            setValidationMessage('Pole NIP jest niepoprawne');
            return;
        }
        const updatedUser = { ...currentUser, company: companyName,  street: street, number: number1, number2: number2, postalCode:postalCode, city: city, country: country, nip: nip};
        setUser(updatedUser)
        let result = await apiLogin.saveCurrentUser(updatedUser);

        if(result)
        {
            setEditMode(false);
            await fetchData();
        }
    }

    const remove = async() => {
        const updatedUser = { ...currentUser, cardTail: null };
        setUser(updatedUser);
        setOpenDelete(false);
        await apiLogin.removePayment(updatedUser);
    }

    const validateNip = (nip) => {
        const cleanedNip = nip.replace(/[^0-9]/g, '');
    
        if (cleanedNip.length !== 10) {
          return false;
        }
    
        const digits = cleanedNip.split('').map(Number);
        const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
        const checksum = weights.reduce((sum, weight, index) => sum + weight * digits[index], 0) % 11;
      
        return checksum === digits[9];
    };

    return (
        <div>
        {props.isOpenPayLoad && !openDelete && 
            <form onSubmit={handleSubmit(onSubmit)}>
            <div class="popup-dialog" id="payload">
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={11}>
                                <h5>Płatności</h5>
                            </Grid>
                            <Grid xs={1}><a onClick={(event)=>{props.setIsOpenPayLoad(false);props.setIsBlur(false);}} class="x"></a></Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className="mt-7">
                        <Grid container>
                            <Grid item xs={11}>
                                <h5><i className='info2-svg'></i>&nbsp;Aktualna subskrypcja</h5>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className="popup-dialog-info mt-7" >
                        <Grid item xs={4}>
                            <span>Status subskrypcji</span>
                            {currentUser.deadline && <p><i className="green-dot"></i>&nbsp;Aktywna</p>}
                            {!currentUser.deadline && <p><i className="red-dot"></i>&nbsp;Nieaktywna</p>}
                        </Grid>
                        {currentUser.deadline && <Grid item xs={4}>
                            <span>Najbliższa data rozliczenia</span>
                            <p>{currentUser.deadline ?? '-'}</p>
                        </Grid>}
                        {currentUser.deadline && 
                        <Grid item xs={4}>
                            <span>Kwota płatności</span>
                            {!currentUser.year && <p>25.00 zł(30.75 zł + VAT)</p>}
                            {currentUser.year && <p>300 zł(369 zł + VAT)</p>}
                        </Grid>}
                    </Grid> 
                    {currentUser.deadline && 
                    <Grid container className="popup-dialog-info mt-10" >
                        <Grid item xs={4}>
                            <span>Okres rozliczeniowy</span>
                            {currentUser.cardTail && <p>Mięsieczny</p>}
                            {!currentUser.cardTail && <p>Jednorazowy</p>}
                        </Grid>
                        <Grid item xs={4}>
                            <span>Rodzaj subskrypcji</span>
                            <p>{currentUser.cardTail ? 'Cykliczna':'Jednorazowa'}</p>
                        </Grid>
                    </Grid> 
                    }
                    <Grid container className='mt-7'>
                        <Grid item xs={12}>
                            <Grid container>
                               <Grid item xs={5}>
                                    <span>Karty płatnicze</span>
                                </Grid>        
                            </Grid>
                            <Grid container className="cart mt-5">
                                {currentUser.cardTail && <Grid container>
                                    <Grid item xs={5}>
                                        <p><i class='card-tail'></i>&nbsp;&nbsp;&nbsp;Karta płatnicza (**** {currentUser.cardTail})</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <p className='gray-color text-right cursor' onClick={()=>setOpenDelete(true)}>Usuń</p>
                                    </Grid>
                                </Grid>}
                                {!currentUser.cardTail && <Grid container>
                                    <Grid item xs={5}>
                                        <p><i class='card-tail'></i>&nbsp;&nbsp;&nbsp;Brak</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                    </Grid>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className="mt-10">
                        <Grid container>
                            <Grid item xs={11}>
                                <h5><i className='keep-svg'></i>&nbsp;Rozpocznij subskrypcję</h5>
                            </Grid>
                        </Grid>
                    </Grid>

                    {!currentUser.cardTail && <Grid item xs={12}>
                        <Grid container>
                            <h4>Płatność</h4>
                        </Grid>
                        <Grid container>
                            <RadioGroup className="radio" defaultValue="1" onChange={(event)=>setOption(event.target.value)}>
                                <FormControlLabel value="1" control={<Radio />} label="Miesięczna - 25,00 zł (30,74 zł z VAT)"/>
                                <FormControlLabel value="2" control={<Radio />} label="Miesięczna (jednorazowa) - 25,00 zł (30,74 zł z VAT)"/>
                                <FormControlLabel value="3" control={<Radio />} label="Roczna (jednorazowa) - 300,00 zł (360,00 zł z VAT)"/>
                            </RadioGroup>
                        </Grid>
                    </Grid>}
                    {!currentUser.cardTail && 
                        <Grid container>
                            <Grid item xs={12}>
                                <Button className="button right" type="submit">
                                    Przedłuż Signsafe na kolejny okres
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    {false && 
                        <Grid container className='mt-10'>
                            <Grid item xs={12}>
                                <p class="checkbox-popup"><input type="checkbox" {...register("isAccept")}/>&nbsp;Potwierdzam, że przeczytałem i zrozumiałem wszystkie <a className='black-font' href="https://signsafe.app/regulamin-serwisu-internetowego/">&nbsp;warunki umowy&nbsp;</a> oraz <a className='black-font' href="https://signsafe.app/polityka-prywatnosci/">&nbsp;politykę prywatności</a>.</p>
                            </Grid>
                        </Grid>
                    }
                    {currentUser.cardTail && <Grid container>
                        <Grid item xs={12}>
                            <p className='small-gray-color'>Aby zmienić metodę płatności, najpierw usuń obecną.</p>
                        </Grid>
                    </Grid>}

                    {currentUser.cardTail && <Grid container>
                        <Grid item xs={10}>
                        </Grid>
                        <Grid item xs={2}>
                            <a className='small-gray-color right underline cursor' onClick={()=>setOpenDelete(true)}>Zrezygnuj z subskrypcji</a>
                        </Grid>
                    </Grid>}

                    <div class="border-blue-top"></div>

                    {!editMode && <Grid container className="payLoad-info">
                        <Grid item xs={6}>
                            <p><i class='invoice'></i>&nbsp;&nbsp;&nbsp;Dane do faktury</p>
                        </Grid>
                        <Grid item xs={6} className='text-right'>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Button className="button button-white right" full-width onClick={()=>setEditMode(true)}>Edytuj dane do faktury</Button>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <p className='small-gray-color'>Zmiana danych na fakturze poskutkuje będzie skuteczna jedynie dla przyszłych płatności.</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}

                    {editMode && <Grid container spacing={2} className="payLoad-info">
                        <Grid item xs={6}>
                            <p><i class='invoice'></i>&nbsp;&nbsp;&nbsp;Dane do faktury</p>
                        </Grid>
                        <Grid item xs={6} className="no-flex text-right">
                            <Grid container>
                                <Grid item xs={12}>
                                    <a className="button button-cancel" onClick={()=>setEditMode(false)}>
                                        {t("sign_text33")}
                                    </a>
                                    <Button className="button" onClick={()=>save(false)}>
                                        Zapisz zmiany
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <p className='small-gray-color'>Zmiana danych na fakturze poskutkuje będzie skuteczna jedynie dla przyszłych płatności.</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>}

                    {validationMessage && 
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className="validation-error">{validationMessage}</div>
                            </Grid>
                        </Grid>
                    }

                    <h4>Dane firmy</h4>
                    <div class="border-top"></div>
                    {editMode && <Grid container spacing={2}>
                        <Grid item xs={8}><label class="MuiFormLabel-root">Imię i nazwisko lub nazwa firmy*</label>
                            <TextField autoComplete='off' {...register("companyName")} placeholder="Nazwa firmy"/>
                        </Grid>
                        <Grid item xs={4}><label class="MuiFormLabel-root">NIP</label>
                            <TextField autoComplete='off' {...register("nip")} placeholder="NIP"/>
                        </Grid>
                    </Grid>}
                    {!editMode && <Grid container spacing={2}>
                        <Grid item xs={8}><label class="MuiFormLabel-root">Imię i nazwisko lub nazwa firmy*</label>
                            <p><span>{currentUser.company}</span></p>
                        </Grid>
                        <Grid item xs={4}><label class="MuiFormLabel-root">NIP</label>
                            <p><span>{currentUser.nip}</span></p>
                        </Grid>
                    </Grid>}
                    <h4>Adres</h4>
                    <div class="border-top"></div>
                    {editMode && <Grid container spacing={2}>
                        <Grid item xs={4}><label class="MuiFormLabel-root">Ulica*</label>
                            <TextField autoComplete='off' {...register("street")}/>
                        </Grid>
                        <Grid item xs={4}><label class="MuiFormLabel-root">Nr domu*</label>
                            <TextField autoComplete='off' {...register("number1")}/>
                        </Grid>
                        <Grid item xs={4}><label class="MuiFormLabel-root">Nr lokalu</label>
                            <TextField autoComplete='off' {...register("number2")} />
                        </Grid>
                    </Grid>}
                    {!editMode && <Grid container spacing={2}>
                        <Grid item xs={4}><label class="MuiFormLabel-root">Ulica*</label>
                            <p><span>{currentUser.street}</span></p>
                        </Grid>
                        <Grid item xs={4}><label class="MuiFormLabel-root">Nr domu*</label>
                            <p><span>{currentUser.number}</span></p>
                        </Grid>
                        <Grid item xs={4}><label class="MuiFormLabel-root">Nr lokalu</label>
                            <p><span>{currentUser.number2}</span></p>
                        </Grid>
                    </Grid>}
                    {editMode && <Grid container spacing={2}>
                        <Grid item xs={4}><label class="MuiFormLabel-root">Kod pocztowy*</label>
                            <TextField autoComplete='off' {...register("postalCode")}/>
                        </Grid>
                        <Grid item xs={4}><label class="MuiFormLabel-root">Miejscowośc*</label>
                            <TextField autoComplete='off' {...register("city")}/>
                        </Grid>
                        <Grid item xs={4}><label class="MuiFormLabel-root">Kraj*</label>
                            <TextField autoComplete='off' {...register("country")}/>
                        </Grid>
                    </Grid>}
                    {!editMode && <Grid container spacing={2}>
                        <Grid item xs={4}><label class="MuiFormLabel-root">Kod pocztowy*</label>
                        <p><span>{currentUser.postalCode}</span></p>
                        </Grid>
                        <Grid item xs={4}><label class="MuiFormLabel-root">Miejscowośc*</label>
                        <p><span>{currentUser.city}</span></p>
                        </Grid>
                        <Grid item xs={4}><label class="MuiFormLabel-root">Kraj*</label>
                        <p><span>{currentUser.country}</span></p>
                        </Grid>
                    </Grid>}
                    {false && 
                    <Grid container className='mt-10'>
                        <Grid item xs={12}>
                            <Button class="MuiButtonBase-root MuiButton-root MuiButton-text button" tabindex="0" type="submit" id="tokenizeButton" style={{height:'38px !important'}}>
                                <span class="MuiButton-label" id="save-info">Przejdź do płatności</span>
                            </Button>
                        </Grid>
                    </Grid>}
                    {false && 
                    <Grid container className='mt-10'>
                        <Grid item xs={12}>
                            <div class="info-label">
                                <p>Płatność realizowana za pomocą serwisu TPay. </p>
                                <p>Możesz anulować subskrypcję w dowolnym momencie.</p>
                            </div>
                        </Grid>
                    </Grid>
                    }
                    <Grid container className='mt-10'>
                        <Grid item xs={12}>
                            <div class="info-label">

                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            </form>
        }
        <Dialog open={openDelete} onClose={()=>setOpenDelete(false)} style={{padding:"10px"}}>
            <DialogContent>
                <DialogContentText>
                    Czy na pewno chcesz usunąć subskrypcję?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <a onClick={()=>setOpenDelete(false)} className="button button-cancel" fullWidth><i className="back"></i>Nie</a>
                    </Grid>
                    <Grid item xs>
                        <Button className="button full-width" onClick={()=>remove()}>
                            <span>Tak</span>
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
        </div>
    );
}

export default PayLoadComponent;