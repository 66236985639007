import React, { useState, useEffect } from 'react';
import { Button,Grid,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Checkbox } from '@mui/material';
import { useForm } from "react-hook-form";
import {apiLogin} from '../../services/api/accounts/Login';
import {authVerify} from "../../services/api/utilities/AuthVerify";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {isMobile,useMobileOrientation } from 'react-device-detect';
import { useTranslation } from "react-i18next";

const UserComponent = (props) => {
    const { register, handleSubmit, reset, setValue} = useForm();
    const [validationMessage, setValidationMessage] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [paymentsMode, setPaymentsMode] = useState(false);
    const [model, setModel] = useState({});
    const { t, i18n, ready } = useTranslation();
    
    useEffect(() => {
        authVerify.verifyUser();
        async function fetchData() {
            let user = await apiLogin.getCurrentUser();
            if(user!==null)
            {
                setModel(user);
                reset(user);
            }
        }
        fetchData();
    }, [reset]);

    const handleClose = () => {
        props.setIsOpenUser(false);
        setEditMode(false);
    };

    const onSubmit = async (data) => {
        model.name = data.name;
        model.surname = data.surname;
        model.company = data.company;
        model.email = data.email;
        model.isLocal = data.isLocal;
        await apiLogin.saveCurrentUser(model);
        let user = await apiLogin.getCurrentUser();
        if(user!==null)
        {
            setModel(user);
            reset(user);
        }
        props.setIsOpenUser(false);
        setEditMode(false);
    }

    const cancel = () => {
        setEditMode(false);
    }
    
    const setEdit = () => {
        setEditMode(true);
    }

    const setPayment = () => {
        setPaymentsMode(true);
    }

    return (
        <Dialog open={props.isOpenUser} onClose={handleClose} style={{padding:"10px"}} maxWidth="lg" fullWidth>
            <div className='dialog-user'>
                <form autoComplete="none" onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>
                    {t("user_text1")}
                </DialogTitle>
                <DialogContent>
                <DialogContentText>
                    <Grid container className="right-side">
                        <Button className="button button-white" full-width onClick={()=>{props.setIsOpenUser(false);props.setIsOpenPayLoad(true);props.setIsBlur(true)}}>{t("user_text2b")}</Button>
                        &nbsp;&nbsp;
                        <Button className="button button-white" full-width onClick={()=>setEdit()}>{t("user_text2")}</Button>
                    </Grid>
                    <br/>
                    <br/>
                    {!isMobile && 
                    <TableContainer className="document-list user">
                        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">{t("user_text3")}</TableCell>
                                    <TableCell align="left">{t("user_text4")}</TableCell>
                                    <TableCell align="left">{t("user_text5")}</TableCell>
                                    {!editMode && <TableCell align="left">{t("user_text5c")}</TableCell>}
                                    <TableCell align="left">{t("user_text5b")}</TableCell>
                                    <TableCell align="left">{t("user_text7")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!editMode && 
                                    <TableRow>
                                        <TableCell align="left">{model.name}</TableCell>
                                        <TableCell align="left">{model.surname}</TableCell>
                                        <TableCell align="left">{model.email}</TableCell>
                                        <TableCell align="left">{model.deadline}</TableCell>
                                        <TableCell align="left"><Checkbox checked={model.isLocal} disabled={true}/></TableCell>
                                        <TableCell align="left">{model.company}</TableCell>
                                    </TableRow>
                                }
                                {editMode && 
                                    <TableRow>
                                        <TableCell align="left"><TextField autoComplete='off' required autoFocus {...register("name", { required: true })} /></TableCell>
                                        <TableCell align="left"><TextField autoComplete='off' required autoFocus {...register("surname", { required: true })} /></TableCell>
                                        <TableCell align="left"><TextField autoComplete='off' required {...register("email", { required: true })} style={{width:"200px"}}/></TableCell>
                                        <TableCell align="left"><Checkbox {...register("isLocal")} defaultChecked={model.isLocal} onChange={e => {setValue("isLocal", e.target.checked)}} color="primary"/></TableCell>
                                        <TableCell align="left"><TextField autoComplete='off' {...register("company")} /></TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    }
                    {isMobile && 
                        <Grid container className="add-person-box">
                            {validationMessage && <Grid container>
                                    <div className="validation-error">{validationMessage}</div>
                                </Grid>
                            }
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField autoComplete='off' autoFocus {...register("name", { required: true })} placeholder={t("user_text3")} disabled={!editMode}/>
                                </Grid>

                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField autoComplete='off' {...register("surname", { required: true })} placeholder={t("user_text4")} disabled={!editMode}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField autoComplete='off' {...register("email", { required: true })} placeholder={t("user_text5")} disabled={!editMode}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12} style={{textAlign:'left', marginTop:'10px'}}>
                                    <Checkbox {...register("isLocal")} defaultChecked={model.isLocal} onChange={e => {setValue("isLocal", e.target.checked)}} color="primary"/> 
                                    {t("user_text5b")}                             
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField autoComplete='off' {...register("company")} placeholder={t("user_text6")} disabled={!editMode}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={2} className="right-side action-margin mobile-buttons">
                            <a className="button button-cancel" fullWidth onClick={()=>handleClose()}>{t("user_text12")}</a>&nbsp;&nbsp;
                            <Button className="button" type="submit">{t("user_text13")}</Button>
                        </Grid>
                    </DialogActions>
                </form>
            </div>
        </Dialog>
    );
}

export default UserComponent;