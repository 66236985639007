import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Container from '@mui/material/Container';
import { useForm } from "react-hook-form";
import { Link } from 'react-router-dom'; 
import {apiLogin} from '../../services/api/accounts/Login';
import { useTranslation } from "react-i18next";
import {Helmet} from "react-helmet";

const LoginComponent = () => {
    const { register, handleSubmit, setValue } = useForm();
    const [validationMessage, setValidationMessage] = useState('');
    const [validationGreenMessage, setValidationGreenMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState(0);
    const [documentId, setDocumentId] = useState(0);
    const { t, i18n, ready } = useTranslation();

    const onSubmit = async (data) => {
        if(!isLoading)
        {
            setIsLoading(true);
            let docId=localStorage.getItem('documentId');
            if(docId)
                setDocumentId(docId);
            let result = await apiLogin.login(data);
            if (result.error) {
                setIsLoading(false);
                setValidationMessage(result.error);
            }
            else {
                setIsLoading(false);
                localStorage.setItem('token', result.token);
                localStorage.setItem('fullName', result.fullName);
                localStorage.setItem('role', result.roleId);
                localStorage.setItem('email', result.name);
                localStorage.setItem('avatar', result.avatar);
                if(docId!==undefined && docId!=='' && docId!==null)
                {
                    window.location.href = "/sign?id="+docId;
                }
                else
                {
                    if(result.roleId===2 || result.roleId===3)
                    {
                        window.location.href = "/create";
                    }
                    else
                    {
                        window.location.href = "/index";
                    }
                }
            }
        }
    };

    let location = useLocation();

    useEffect(() => {
        let email = localStorage.getItem('email');
        if(email!==null && email!=='')
        {
            window.location.href = "/index";
        }

        if(location.search.indexOf('login')>=0)
        {
            email = location.search.substring(location.search.indexOf('login')+6,location.search.length);
            setValue('login',email);
        }

        if (location.search.indexOf('type=1') >= 0) {
            setType(1);
        }
        else if (location.search.indexOf('type=2') >= 0) {
            setType(2);
        }
        else if (location.search.indexOf('type=3') >= 0) {
            setType(3);
        }
    }, []);

    return (
        <Grid container className="center-vertical-item">
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
            </Helmet>
            <Container component="main" maxWidth="sm" className="login-container height100">
                <a className="logo-small"></a>
                <Grid container direction="column" className="height100">
                    <Grid className="login-first-row">
                        <form autoComplete="none" onSubmit={handleSubmit(onSubmit)} className="login-form">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <h1>{t("app_text7")}</h1>
                                </Grid>
                                <Grid item xs={12}>
                                    {validationMessage && documentId>0 && <div className="validation-error">{t("app_text19")}</div>}
                                    {validationMessage && documentId<=0 && <div className="validation-error">{t("app_text19")}</div>}
                                </Grid>
                                <Grid item xs={12}>
                                    {type===1 && 
                                        <div className="validation-green-error">{t("app_text16")}</div>
                                    }
                                    {type===2 && 
                                        <div className="validation-green-error">{t("app_text17")}</div>
                                    }
                                    {type===3 && 
                                        <div className="validation-green-error">{t("app_text18")}</div>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel>{t("app_text8")}</FormLabel>
                                    <TextField autoComplete='off' required autoFocus {...register("login", { required: true })} /><br />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel>{t("app_text9")}</FormLabel>
                                    <TextField autoComplete='off' required type="password" name="password" {...register("password", { required: true })} /><br />
                                </Grid>
                                <Grid item xs={12}>
                                    <Link to="/forgot" className="blue-font">{t("app_text10")}</Link>
                                </Grid>
                                <br/>
                                <Grid item xs={12}>
                                    <Button type="submit" className="button" fullWidth color="primary">
                                        {isLoading && 
                                            <span className="loader"></span>
                                        }
                                        {!isLoading && 
                                            <span>{t("app_text11")}</span>
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid className="login-options">
                        <div className="space"></div>
                        <div className="bottom-line">
                            <Link to="/registration">{t("app_text12")}</Link>
                            <a href="https://signsafe.app/regulamin-serwisu-internetowego/" target="_blank">{t("app_text14")}</a>
                            <a href="https://signsafe.app/polityka-prywatnosci/" target="_blank">{t("app_text15")}</a>
                            <p>{t("app_text15b")}</p>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
}

export default LoginComponent;