import React, { useState, useEffect, useRef } from 'react';
import { Button,Grid,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useForm } from "react-hook-form";
import {apiImageRepo} from '../../services/api/imageRepo/ImageRepo';
import { apiDocument } from '../../services/api/documents/Document';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from "react-i18next";
import axios from 'axios';

const ImageRepoComponent = (props) => {
    const [items, setItems] = useState([]);
    const [validationMessage, setValidationMessage] = useState('');
    const [isEditMode, setEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [file, setFile] = useState({});
    const [fileName, setFileName] = useState('');
    const [id, setIdValue] = useState(-1);
    const { register,  getValues, setValue } = useForm();
    const { t, i18n, ready } = useTranslation();
    const inputRef = useRef();

    useEffect(() => {
        async function fetchData() {
            await refreshItems();
        }
        fetchData();
    }, []);

    const handleClose = () => {
        props.setIsOpenImageRepo(false);
        window.location.reload();
    };

    const removeImageRepo = async () => {
        await apiImageRepo.remove({id: id});
        await refreshItems();
        setOpenDelete(false);
    };

    const showRemove = (id) => {
        setIdValue(id);
        setOpenDelete(true);
    };

    const setImageRepo = (id,name) => {
        setIdValue(id);
        setValue("name",name);
        setEditMode(true);
    }

    const cancel = () => {
        setValue("name","");
        setEditMode(false);
    }

    const refreshItems = async () => {
        let imageRepos = await apiImageRepo.search({start:0, end:9999});
        if(imageRepos!==null)
        {
            setItems(imageRepos.items);
        }
    }

    const add = async () => {
        setEditMode(true);
        setIdValue(0);
    }

    const addImageRepo = async () => {
        var name = getValues("name");
        if(name===null || name==='')
        {
            setValidationMessage(t("users_text21"));
            return;   
        }
        
        let user =null;
        
        if(id<=0)
            user = await apiImageRepo.save({name:name, src: file.fileName});
        else
            user = await apiImageRepo.save({id:id, name:name, src: file.fileName});
        if(user!=null)
        {
            refreshItems();
            setEditMode(false);
        }
    }

    const changeFile = async (a)=>{
        let ev = a.target;
        apiDocument.uploadImageRepoFile(ev.files[0]).then((file)=>{
            if(file!==null)
            {
                setValidationMessage('');
                debugger;
                if(file.data.error)
                {
                    setValidationMessage(t("create_text4c"));
                    return;
                }
                setFile(file.data);
                let oryginalFileName = file.data.oryginalFileName.replaceAll('"','');
                setFileName(oryginalFileName);
            }
        });
    }

    return (
        <div>
            <Dialog open={props.isOpenImageRepo} onClose={handleClose} style={{padding:"10px"}} maxWidth="lg" fullWidth>
                <div className='dialog-user'>
                    <DialogTitle>
                        Repozytorium obrazów
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                        <Grid container className="right-side">
                            <Button className="button button-white" onClick={()=>add()}>Dodaj obraz</Button>
                        </Grid>
                        <br/>
                        <br/>
                        {isEditMode && 
                            <Grid container className="add-person-box">
                                <Grid item xs={12}>
                                    <h3>{id<=0 ? 'Dodanie nowego obrazu':'Edycja obrazu'}</h3>
                                </Grid>
                                {validationMessage && <Grid container>
                                    <div className="validation-error">{validationMessage}</div>
                                </Grid>
                                }
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField autoComplete='off' {...register("name", { required: true })} placeholder='Nazwa'/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <input ref={inputRef} type="file" onChange={(e) => {
                                        changeFile(e, 'a');
                                    }}/>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} spacing={1}>
                                    <Grid item xs={6}>
                                        <Button className="button-person" onClick={()=>addImageRepo()} fullWidth>{t("users_text8")}</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button className="button-person" onClick={()=>cancel()} fullWidth style={{marginLeft:10}}>{t("users_text9")}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {isEditMode && 
                            <br/>
                        }
                        <Grid>
                            <TableContainer className="document-list user user-list">
                                <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Nazwa</TableCell>
                                            <TableCell align="left"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {items.map((row, index) => {
                                            return(
                                            <TableRow key={index}>
                                                <TableCell align="left">{row.name}</TableCell>
                                                <TableCell align="left"><a className="x" onClick={()=>showRemove(row.id)}></a>&nbsp;&nbsp;&nbsp;&nbsp;<a className='user-list-edit' onClick={()=>setImageRepo(row.id, row.name)}></a></TableCell>
                                            </TableRow>);
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions >
                        <Grid container spacing={2} className="right-side action-margin">
                            <a className="button button-cancel" fullWidth onClick={()=>handleClose()}>Zamknij</a>&nbsp;&nbsp;
                        </Grid>
                    </DialogActions>
                </div>
            </Dialog>
            <Dialog open={openDelete} onClose={()=>setOpenDelete(false)} style={{padding:"10px"}}>
                <DialogContent>
                    <DialogContentText>
                        Czy na pewno chcesz usunąć plik?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <a onClick={()=>setOpenDelete(false)} className="button button-cancel" fullWidth><i className="back"></i>Nie</a>
                        </Grid>
                        <Grid item xs>
                            <Button className="button full-width" onClick={()=>removeImageRepo()}>
                                {isLoading && 
                                    <span className="loader"></span>
                                }
                                {!isLoading && 
                                    <span>Tak</span>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ImageRepoComponent;