import React, { useState, useEffect, useRef } from 'react';
import { Button,Grid, FormControl,TextField, FormLabel } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useForm } from "react-hook-form";
import {apiDocument} from '../../services/api/documents/Document';
import Markers from '../markers/Markers';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { apiProvider } from '../../services/api/utilities/Provider';
import { useTranslation } from "react-i18next";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {apiImageRepo} from '../../services/api/imageRepo/ImageRepo';

const TemplateComponent = (props) => {
    const { t, i18n, ready } = useTranslation();
    const [isField, setIsField] = useState(false);
    const [isFieldPESEL, setIsFieldPESEL] = useState(false);
    const [isFieldDate, setIsFieldDate] = useState(false);
    const [imgItems, setImgItems] = useState([]);
    const [range, setRange] = useState(0);
    const [isSurvey, setIsSurvey] = useState(false);
    const [signer1, setSigner1] = useState(null);
    const [questionType, setQuestionType] = useState(1);
    const [signer2, setSigner2] = useState(null);
    const [imageRepo, setImageRepo] = useState(null);
    const { register,  getValues, setValue } = useForm();
    const [surveys, setSurveys] = useState([]);
    const [validationMessage, setValidationMessage] = useState('');
    const [answers, setAnswers] = useState([]);
    const quillRef = useRef();
    const [isAnswer, setIsAnswer] = useState(false);

    const refreshItems = async () => {
        let imageRepos = await apiImageRepo.search({start:0, end:9999});
        if(imageRepos!==null)
        {
            setImgItems(imageRepos.items);
        }
    }

    useEffect(() => {
        async function fetchData() {
            await refreshItems();
        }
        fetchData();
    }, []);

    useEffect(() => {
        if(props.template)
        {
            setValue("name",props.template.name);
            quillRef.current.editor.container.firstChild.innerHTML=props.template.body;
        }
        else
        {
            setValue("name",null);
        }
    }, [props.template]);
    
    const handleClose = () => {
        props.setIsOpenDocuments(false);
    };

    const cancel = () => {
        props.setIsOpenDocuments(false);
    }

    const putMyPlace = async () => {
        var fieldName = getValues("fieldName");
        if(fieldName)
        {
            if(isFieldPESEL)
            {
                quillRef.current.editor.insertText(range,'##SignsafePESELField: '+fieldName+'##');
                quillRef.current.editor.setSelection(range+('##SignsafePESELField: '+fieldName+'##').length, 0);
            }
            else if(isFieldDate)
            {
                quillRef.current.editor.insertText(range,'##SignsafeDateField: '+fieldName+'##');
                quillRef.current.editor.setSelection(range+('##SignsafeDateField: '+fieldName+'##').length, 0);
            }
            else
            {
                quillRef.current.editor.insertText(range,'##SignsafeField: '+fieldName+'##');
                quillRef.current.editor.setSelection(range+('##SignsafeField: '+fieldName+'##').length, 0);
            }
            setValue("fieldName","");
            setIsField(false);
            setIsFieldPESEL(false);
            setIsFieldDate(false);
        }
    }

    const putToday = async() => {
        quillRef.current.editor.insertText(range,'!!Today!!');
        quillRef.current.editor.setSelection(range+('!!Today!!').length, 0);
    }

    const cancelMyPlace = async () => {
        setValue("fieldName","");
        setIsField(false);
        setIsFieldPESEL(false);
        setIsFieldDate(false);
    }

    const changeSelection = (ev)=>{
        const ran = quillRef.current.editor.getSelection()?.index;
        if(ran)
        {
            setRange(ran);
        }
    }

    const putSurvey = async () => {
        const range = quillRef.current.editor.getSelection(true)?.index;
        
        if(range)
        {
            let questionName= getValues("question");
            surveys.push({questionName, answers: answers});
            let htmlText = '##SignsafeSurvey:'+questionType+'##<br/>'+questionName+'<ul data-checked="false">';
            answers.forEach(element => {
                htmlText += '<li>'+element.answerName+'</li>';
            });
            htmlText+='</ul><p>##SignsafeSurvey##</p>';
            quillRef.current.editor.clipboard.dangerouslyPasteHTML(range, htmlText);
            setAnswers([]);
            setValue('question','');
            setIsSurvey(false);
        }
    }

    const downloadDocument = async () => {        
        const result = await apiDocument.generateDocument({htmlData: quillRef.current.editor.root.innerHTML})

        if(result && result.path)
        {
            window.open(`${apiProvider.BASE_URL}${result.path}`, '_blank');
        }
    }

    const saveTemplate = async () => {
        let name = getValues('name');
        if(!name)
        {
            setValidationMessage('Pole nazwa wzorca nie może być puste');
        }
        const result = await apiDocument.saveTemplate({id:props.template?.id, name: name, surveys: surveys,body: quillRef.current.editor.root.innerHTML})
        if(result && !result.error)
        {
            handleClose();
            props.refreshItems();
        }
    }

    const changeSigners1 = async (event) =>{
        if(event.target.value!=='')
        {
            quillRef.current.editor.insertText(range,'##SignsafeMy'+event.target.value+'##');   
        }
        setSigner1(null);
    }

    const changeSigners2 = async (event) =>{
        if(event.target.value!=='')
        {
            quillRef.current.editor.insertText(range,'##SignsafeTheir'+event.target.value+'##');
        }
        setSigner2(null);
    }

    const changeImageRepo = async (event) =>{
        if(event.target.value!=='')
        {
            quillRef.current.editor.insertText(range,'##ImageRepo'+event.target.value+'##');
        }
        setImageRepo(null);
    }

    const addAnswer = () => {
        let answerName = getValues("answer");
        if(answerName===null || answerName==='')
        {
            setValidationMessage('Pole treść odpowiedzi nie może być puste');
            return;   
        }
        
        setValidationMessage('');
        answers.push({answerName:answerName});
        setAnswers([...answers]);
        setValue('answer','');
        setIsAnswer(false);
    }

    const cancelAnswer = () => {
        setValue('answer','');
        setIsAnswer(false);
    }

    const removeAnswer = (row) => {
        let index = answers.indexOf(row);
        answers.splice(index, 1);
        setAnswers([...answers]);
    }

    const cancelSurvey = () => {
        setAnswers([]);
        setValue('answer','');
        setIsSurvey(false);
    }

    return (
        <div>
            <Dialog open={props.isOpenDocuments} onClose={handleClose} style={{padding:"10px"}} maxWidth="lg" fullWidth>
                <div className='dialog-user dialog-template'>
                    <DialogTitle>
                        {t("template_text8b")}
                    </DialogTitle>
                    <DialogContent>
                        <div className="fixed-position">
                        {validationMessage && <Grid container>
                            <div className="validation-error validation-document">{validationMessage}</div>
                            </Grid>
                        }
                        <Grid container className='form-quill'>
                            <FormLabel>{t("template_text9b")}</FormLabel>
                            <TextField autoComplete='off' required autoFocus {...register("name", { required: true })} inputProps={{ maxLength: 50 }}/><br />
                        </Grid>
                        <Grid container className='form-quill2'>
                            <FormControl sx={{ minWidth: 180 }} className='min-width-mobile'>
                                <InputLabel id="labelSigner2">{t("template_text15b")}</InputLabel>
                                <Select className=''
                                    label={t("template_text15c")} 
                                    labelId="labelSigner2"
                                    id="demo-select-small" onChange={(d)=>changeSigners2(d)} value={signer2}>
                                    <MenuItem value={1}>{t("template_text16")}</MenuItem>
                                    <MenuItem value={2}>{t("template_text17")}</MenuItem>
                                    <MenuItem value={3}>{t("template_text18")}</MenuItem>
                                    <MenuItem value={4}>{t("template_text19")}</MenuItem>
                                    <MenuItem value={5}>{t("template_text20")}</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl sx={{ minWidth: 180 }} className='second-select min-width-mobile'>
                                <InputLabel id="labelSigner3">{t("template_text15d")}</InputLabel>
                                <Select className=''
                                    label={t("template_text15d")} 
                                    labelId="labelSigner3"
                                    id="demo-select-small" onChange={(d)=>changeImageRepo(d)} value={imageRepo}>
                                    {imgItems.map((row, index) => {
                                        return (
                                        <MenuItem value={row.id}>{row.name}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                            <Button onClick={()=>setIsField(true)} className='button-wysiwyg'>{t("template_text21")}</Button>
                            <Button onClick={()=>setIsFieldPESEL(true)} className='button-wysiwyg'>{t("template_text21b")}</Button>
                            <Button onClick={()=>setIsFieldDate(true)} className='button-wysiwyg'>{t("template_text21c")}</Button>
                            <Button onClick={()=>putToday(true)} className='button-wysiwyg'>{t("template_text21d")}</Button>
                            <Button onClick={()=>setIsSurvey(true)} className='button-wysiwyg'>{t("template_text22")}</Button>
                        </Grid>
                        {(isField || isFieldPESEL || isFieldDate) && 
                            <Grid container className='form-quill'>
                                <FormLabel>{t("template_text22b")}</FormLabel>
                                <TextField autoComplete='off' required autoFocus {...register("fieldName", { required: true })} /><br />
                            </Grid>
                        }
                        {(isField || isFieldPESEL || isFieldDate) && 
                            <Grid container xs={12}className='form-quill'>
                                <Grid item xs={8}>
                                    
                                </Grid>
                                <Grid item xs={2} className="mobile-left-offset">
                                    <Button className="button button-cancel" onClick={()=>cancelMyPlace()} fullWidth style={{width:'100px',float:'right', marginRight:'10px'}}>{t("template_text23")}</Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button className="button" fullWidth onClick={()=>putMyPlace()}>{t("template_text24")}</Button>
                                </Grid>
                            </Grid>
                        }
                        {isSurvey && 
                        <div className='survey-question'>
                        {isSurvey && 
                            <Grid container className='form-quill'>
                                <Grid item xs={7} className="full-width-mobile">
                                    <FormLabel>{t("template_text25")}</FormLabel>
                                    <TextField autoComplete='off' placeholder='Treść pytania?' required autoFocus {...register("question", { required: true })} /><br />
                                </Grid>
                                <Grid item xs={5} className="full-width-mobile">
                                    <FormControl sx={{ minWidth: 280 }} className="survey-select">
                                        <Select
                                            labelId="labelKindQuestion"
                                            value={questionType} onChange={(event)=>setQuestionType(event.target.value)}>
                                            <MenuItem value={1}>{t("template_text26")}</MenuItem>
                                            <MenuItem value={2}>{t("template_text27")}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        }
                        <div className='survey-background'>
                        {isSurvey && 
                            <Grid container className='form-quill' style={{marginBottom:'0px'}}>
                                {answers.map((row, index) => {
                                    return(<Grid item xs={12} className={`box-item`}>
                                        <Grid container>
                                            <Grid item xs={11}>
                                                <p>
                                                    {questionType===1 && <input type="radio" disabled/>}
                                                    {questionType===2 && <input type="checkbox" disabled/>}
                                                    {row.answerName}
                                                </p>
                                            </Grid>
                                            <Grid item xs={1} className="right-element">
                                                <a className="x" onClick={()=>{removeAnswer(row)}}></a>
                                            </Grid>
                                        </Grid>
                                    </Grid>);
                                })}
                            </Grid>
                        }
                        {isSurvey && 
                            <Grid container className='form-quill'>
                                {!isAnswer && 
                                    <Grid item xs={12}>
                                        <a onClick={()=>setIsAnswer(true)}>{t("template_text28")}</a>
                                    </Grid>
                                }
                                {isAnswer && 
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={4} className='min-width-200'>
                                                <TextField autoComplete='off' required autoFocus {...register("answer", { required: true })} /><br />
                                            </Grid>
                                            <Grid item xs={4} className='min-width-200'>
                                                <Button onClick={()=>addAnswer()} className='button-wysiwyg'>{t("template_text29")}</Button>&nbsp;
                                                <Button onClick={()=>cancelAnswer()} className='button-wysiwyg'>{t("template_text30")}</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        }
                        </div>
                        {isSurvey && 
                            <Grid container xs={12} spacing={1} className='form-quill'>
                                <Grid item xs={8}>
                                    
                                </Grid>
                                <Grid item xs={2} className="mobile-left-offset">
                                    <Button className="button button-cancel" onClick={()=>cancelSurvey()} fullWidth style={{width:'100px',float:'right'}}>{t("template_text30")}</Button>
                                </Grid>
                                <Grid item xs={2} style={{paddingRight:10}}>
                                    <Button className="button" fullWidth onClick={()=>putSurvey()}>{t("template_text31")}</Button>
                                </Grid>
                            </Grid>
                        }
                        </div>
                        }
                        </div>
                        <Grid className="quifill-area">
                            <ReactQuill theme="snow" 
                            ref={quillRef}
                            onChangeSelection={changeSelection}
                            modules = {
                            {toolbar: [
                            [{ 'header': [1, 2, false] }],
                            ['bold', 'italic'],
                            [{'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
                            //[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                            ['clean']
                            ]}}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions >
                        <Grid container xs={12} spacing={1}>
                            <Grid item xs={1}>
                                <Button className="button button-cancel not-mobile" onClick={()=>cancel()} fullWidth><i className="back not-mobile" style={{left:'22px'}}></i>{t("template_text32")}</Button>
                            </Grid>
                            <Grid item xs={7}>
                                
                            </Grid>
                            <Grid item xs={2}>
                                <Button className="button button-cancel template-document-download" onClick={()=>downloadDocument()} fullWidth><i className="eye not-mobile" style={{left:'15px'}} ></i>{t("template_text33")}</Button>
                            </Grid>
                            <Grid item xs={2} style={{paddingRight:10}}>
                                <Button className="button button-save-template" fullWidth onClick={()=>saveTemplate()}>{t("template_text34")}</Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default TemplateComponent;