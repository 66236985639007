import React, { useState, useEffect } from 'react';
import { Grid,  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,TextField, Checkbox} from '@mui/material';
import {apiDocument} from '../../services/api/documents/Document';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useTranslation } from "react-i18next";

const ContactListComponent = (props) => {
    const [items, setItems] = useState([]);
    const [baseItems, setBaseItems] = useState([]);
    const [validationMessage, setValidationMessage] = useState('');
    const { t, i18n, ready } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [editTemplate, setEditTemplate] = useState(null);
    const [id, setIdValue] = useState(-1);

    useEffect(() => {
        async function fetchData() {
            let contacts = await apiDocument.contacts();
            if(contacts!==null)
            {
                setItems(contacts.items);
                setBaseItems(contacts.items);
            }
        }
        fetchData();
    }, [props.isOpenContactList]);

    const handleClose = async () => {
        let selectedItems = items.filter(d=>d.isChecked);
        if(selectedItems.length>0)
        {
            for(var i =0;i<selectedItems.length;i++)
            {
                await apiDocument.saveContactToGroup({contactId: selectedItems[i].id, contactGroupId: props.groupId});
            }
        }
        props.setOpenContactList(false);
        props.refreshItems();
    };

    const search = async(query) => {
        if(query)
        {
            let result = baseItems.filter(d=>(d.firstName+' '+d.lastName+' '+d.email+' '+d.company).toLowerCase().indexOf(query.toLowerCase())>=0);
            setItems(result);
        }
        else
        {
            setItems(baseItems);
        }
    }

    return (
        <div>
            <Dialog open={props.isOpenContactList} onClose={handleClose} style={{padding:"10px"}} maxWidth="lg" fullWidth>
                <div className='dialog-user'>
                    <DialogTitle>
                        Wybierz osoby do grupy "{props.groupName}"
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Grid>
                                <Grid container>
                                    <Grid item xs>
                                    
                                    </Grid>
                                    <Grid item style={{width: "300px"}} className="right-element">
                                        <i className="search"></i>
                                        <TextField autoComplete='off' placeholder={t("index_text12")} className='search-input' onChange={(e)=>search(e.target.value)}/>
                                    </Grid>
                                </Grid>
                                <TableContainer className="contact-list user">
                                    <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left"></TableCell>
                                                <TableCell align="left">{t("contact_text2")}</TableCell>
                                                <TableCell align="left">{t("contact_text3")}</TableCell>
                                                <TableCell align="left" className="not-mobile">{t("contact_text4")}</TableCell>
                                                <TableCell align="left" className="not-mobile">{t("contact_text5")}</TableCell>
                                                <TableCell align="left" className="not-mobile">{t("contact_text6")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {items.map((row, index) => {
                                                return (
                                                <TableRow key={index}>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox color="primary" checked={row.isChecked} onClick={(e)=>{row.isChecked = e.target.checked; setItems([...items]);}}/>
                                                    </TableCell>
                                                    <TableCell align="left">{row.firstName}</TableCell>
                                                    <TableCell align="left">{row.lastName}</TableCell>
                                                    <TableCell align="left" className="not-mobile">{row.email}</TableCell>
                                                    <TableCell align="left" className="not-mobile">{row.company}</TableCell>
                                                    <TableCell align="left" className="not-mobile">{row.companyNumber}</TableCell>
                                                </TableRow>);
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={2} className="right-side action-margin mobile-buttons">
                            <a className="button button-template-mobile" fullWidth onClick={()=>handleClose()}>Dodaj osoby do grupy</a>&nbsp;&nbsp;
                        </Grid>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}

export default ContactListComponent;